<template>
  <div class="search-table">
    <v-container grid-list-xl fluid>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>{{
              $vuetify.lang.t('$vuetify.different.trackingSearch')
            }}</v-card-title>
            <v-toolbar flat color="white">
              <v-text-field
                text
                solo
                flat
                clearable
                prepend-icon="search"
                :placeholder="$vuetify.lang.t('$vuetify.toolbar.search')"
                v-model="search"
                hide-details
                class="hidden-sm-and-down"
              ></v-text-field>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="compHeader"
                :items="items"
                fixed-header
                class="elevation-1"
                item-key="id"
                :loading="tableLoading"
                :items-per-page="-1"
              >
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      :timeout="3000"
      bottom
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="snackbar.show = false" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableLoading: false,
      search: null,
      awaitingSearch: false,
      items: [],
      snackbar: {
        show: false,
        text: '',
        color: ''
      }
    }
  },
  watch: {
    search(val) {
      if (val && val.length >= 3) {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.searchInit()
            this.awaitingSearch = false
          }, 1000)
        }
        this.awaitingSearch = true
      } else this.items = []
    }
  },
  metaInfo() {
    return {
      title: this.$vuetify.lang.t('$vuetify.toolbar.search')
    }
  },
  computed: {
    compAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          responseType: 'application/json'
        }
      }
    },
    compHeader() {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.order.number'),
          value: 'ordernumber'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.order.tracking'),
          value: 'trackingcode'
        }
      ]
    }
  },
  mounted() {},
  methods: {
    searchInit() {
      this.tableLoading = true
      let data = {
        project_id: 2,
        search: this.search
      }

      if (this.search) {
        this.$axios
          .post(
            this.$store.getters.getApiUrl + 'api/search/index',
            data,
            this.compAuthHeader
          )
          .then((response) => {
            if (response.data.success) {
              this.items = response.data.items
            } else {
              this.showSnackbar(
                'red',
                this.$vuetify.lang.t('$vuetify.errors.error')
              )
            }
            this.tableLoading = false
          })
          .catch((error) => {
            console.log(error)
            this.tableLoading = false
            this.showSnackbar(
              'red',
              this.$vuetify.lang.t('$vuetify.errors.error')
            )
          })
      } else this.items = []
    },
    showSnackbar(color, text) {
      this.snackbar.show = true
      this.snackbar.color = color
      this.snackbar.text = text
    }
  }
}
</script>
